import Icon from '../../../../components/Icon'
import React from 'react'
import { useUpdateEmployeeDeployment } from '../hooks/useUpdateEmployeeDeployment'
import { EmployeeDeploymentStatus } from '../../../../__generated__/graphql'
import { useSelectLoanEmployeeDeployment } from '../hooks/useSelectEmployeeDeployment'

export interface DeselectEmployeeButtonProps {
  employeeDeploymentId: string
}

export const DeselectEmployeeButton: React.FC<DeselectEmployeeButtonProps> = ({
  employeeDeploymentId,
}) => {
  const [mutate] = useUpdateEmployeeDeployment({})
  const [selectLoan] = useSelectLoanEmployeeDeployment()

  return (
    <button
      onClick={async () => {
        await Promise.all([
          mutate({
            variables: {
              id: employeeDeploymentId,
              data: { status: EmployeeDeploymentStatus.Candidate },
            },
          }),
          selectLoan({
            variables: {
              employeeDeploymentId,
              selectBool: false,
            },
          }),
        ])
      }}
    >
      <Icon type="close" />
    </button>
  )
}
